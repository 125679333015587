import instance from "../request";
// 获取密文
export const getPublicKey = () => instance.get("/oem/user/getPublicKey")
// 获取验证码
export const sendPhone = (params) => instance.post("/oem/hk/user/getSmsCode", params)
// 登陆
export const login = (params) => instance.post("/oem/hk/user/login", params)
// 退出
export const out = (params) => instance.post("/oem/user/outlogin", params)
// 注册
export const register = (params) => instance.post("/oem/hk/user/register", params)
// 校验
export const checkphone = (params) => instance.post("/oem/user/checkphone", params)
// 找回密码
export const updatepassword = (params) => instance.post("/oem/hk/user/updatepassword", params)


// 邮箱登录
export const emailLogin = (params) => instance.post("/oem/user/",params)

// 获取时长/api/wetauser/getPhoneInfo
export const getPhoneInfo = (params) => instance.get(`/oem/hk/user/getPhoneInfo?randomStr=${params.randomStr}&phone=${params.phone}`)

// 判断是否允许获取用户列表 
export const isAuth = (params) => instance.post(`/oem/hk/user/has/allocation`,params)


// 获取用户列表
export const getUserList = (params) => instance.get("/oem/hk/user//list",{
    params:params
})


// 分配时长 /oem/hk/user/allocation
export const allocation = (params) => instance.post(`/oem/hk/user/allocation?phone=${params.randomStr}&time=${params.time}&type=${params.type}&expirationDate=${params.expirationDate}`)


// 校验是否已经注册
export const check = (params) => instance.post("/oem/hk/user/check/email",params)


// 其他的iframe接口
export const iframes = (params) => instance.post("/oem/user/qmyToken", params)