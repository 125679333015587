import axios from "axios";
axios.defaults.withCredentials = true; //true允许跨域
const instance = axios.create({
    baseURL: 'http://digiman.yunbiao.tv',
    baseURL: ' ',
    timeout: 30000,
    headers: { 'X-Custom-Header': 'foobar' }
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token")
    if (token) {
        config.headers["token"] = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    return Promise.reject(error);
});

export default instance