<template>
  <!-- 固定头部 -->
  <div>
    <!-- header -->
    <div class="header" v-if="!isLogin" :class="{ 'white': showWhite }"
      :style="{ backgroundColor: path == '/help' ? 'transparent' : '' }">
      <div class="left">
        <div class="logo">
          <img src="./assets/02@2x.png" alt="" />
        </div>
        <div class="btn_list">
          <div :class="{ active: item.router == path }" v-for="(item, index) in menuData" :key="index"
            @click="changeActive(item, index)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="login" v-if="!getToen" @click="logins">
          <span>登錄&nbsp;/&nbsp;註冊</span>
        </div>
        <div class="header-right" v-else>
          <el-dropdown>
            <div class="header-avatar">
              <div class="avatar-outer">
                <div class="avatar-inner"></div>
                <div class="avatar-vip">vip</div>
              </div>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goVideoCreated">
                  <el-icon>
                    <User />
                  </el-icon>视频制作</el-dropdown-item>
                <el-dropdown-item @click="goUser">
                  <el-icon>
                    <User />
                  </el-icon>個人中心</el-dropdown-item>
                <el-dropdown-item @click="out"><el-icon>
                    <SwitchButton />
                  </el-icon>退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script setup>
import { ref, onMounted, watch, triggerRef } from "vue";
import { useRouter } from "vue-router"
import * as api from "@/api/login/login.js"

// 退出逻辑
const router = useRouter()
const getToen = ref()

const out = () => {
  api.out({
    Headers: {
      token: localStorage.getItem("token")
    }
  }).then(res => {
    localStorage.removeItem("token")
    getToen.value = false
    router.push({
      path: '/login',
    })
  })
}

onMounted(() => {
  getToen.value = localStorage.getItem("token") // 判断是否有token
})

const logins = () => { // 去登陆
  if (getToen) {
    router.push({
      path: '/login',
    })
  }
}

//菜单头部选择
const activeIndex = ref(0);
const menuData = ref(
  [
    // {
    //   title: "視頻制作",
    //   router: "/",
    // },
    // {
    //   title: "直播中心",
    //   router: "/online",
    // },
    // {
    //   title: "會員中心",
    //   router: "/vipcenter",
    // },
    // {
    //   title: "幫助中心",
    //   router: "/help",
    // }
  ]);
const changeActive = (item, index) => {
  activeIndex.value = index;
  router.push({
    path: item.router,
  })
}
const goUser = () => {
  router.push({
    path: '/memberCenter'
  })
}
const goVideoCreated = () => {
  router.push({
    path: '/createVideo'
  })
}

// 路由监听
const path = ref()
const isLogin = ref(false)
let showWhite = ref(false)
watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
  path.value = newValue
  if ('/login' == newValue) {
    console.log(123)
    isLogin.value = true
  } else if ('/createVideo' == newValue || '/onlineVideo' == newValue) {
    getToen.value = localStorage.getItem("token") // 判断是否有token
    showWhite.value = false;

    isLogin.value = false
  }
  console.log(isLogin.value)
  if ('/' == newValue) {
    isLogin.value = true
    if (localStorage.getItem("token")) {
      getToen.value = localStorage.getItem("token") // 判断是否有token
    }
  }
}, { immediate: true })
// 监听滚动条

// onMounted(() => {
//   window.addEventListener('scroll', scrollTop, true)
// })
// 实时滚动条高度
const scrollTop = () => {
  let scroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (scroll > 80) {
    showWhite.value = true
  } else {
    showWhite.value = false
  }
}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20rem;
  background-color: transparent;
}



.header {
  justify-content: space-between;
  width: 100%;
  height: 60rem;
  background-color: transparent;
  padding: 15rem 50rem;
  display: flex;
  color: white;
  position: fixed;
  z-index: 999;
  transition: all .3s;

  .left {
    display: flex;

    .logo {
      height: 60rem;
      width: 160rem;
      position: absolute;
      top: 0%;
      display: flex;
      align-items: center;

      img {
        height: 50%;
      }
    }

    .btn_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 150rem;

      >div {
        margin-right: 20rem;
        font-size: 16rem;
        cursor: pointer;

        &:hover {
          color: #006eff;
        }
      }
    }
  }

  .right {
    .login {
      padding: 0rem 10rem;
      height: 25rem;
      background: linear-gradient(134deg, #00a4ff 0%, #006eff 100%);
      box-shadow: 0 10rem 28rem -8rem rgba(91, 131, 255, 0.3019607843);
      border-radius: 28rem;
      -o-border-image: linear-gradient(180deg, rgba(86, 114, 255, 0), #d4dcff) 1 1;
      border-image: linear-gradient(180deg, rgba(86, 114, 255, 0), #d4dcff) 1 1;
      color: #fff;
      text-align: center;
      line-height: 25rem;
      font-size: 14rem;
      cursor: pointer;

      span {
        font-size: 13px;
      }
    }

    .header-right {
      position: relative;
      top: -7px;
      left: 0;

      .el-tooltip__trigger:focus {
        outline: none;
      }

      .header-avatar {
        margin-bottom: 10rem;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        position: relative;

        .avatar-outer {
          width: 32px;
          border-radius: 16px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          .avatar-inner {
            background: url("./assets/user.svg") no-repeat;
            width: 28rem;
            height: 28rem;
            border-radius: 16px;
            background-size: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
          }

          .avatar-vip {
            background-size: 100%;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            color: #fafafa;
            font-weight: 500;
            overflow: hidden;
            margin-top: 4px;
            width: 26px;
            height: 12px;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAADgAAAADSd/OWAAACd0lEQVQ4EW1Vu24UQRDsnrMQAhMgfgJBCILYEoFFfpb4An6HCzApIiQjQWTEvELICcgR9klId7dDVXX3zoIY2Tu93VXV1TNr2W2xLjbrZ735U3O7as3Mm+PBHSDtvoizTpyz2M1Xhccu/uCVTmruQHhr1249WVX/i83ZydT9BYBHzEFCvyKigZpYVzLiAFQsD3qQDRi98PFPnHrse9v22x+cTeuwt9Peu/UJk1RSkZvyqKk7t4iMfljj4lb8SDCpKGsRVy7frs8GcIQPc8AQKkEoY36JRTMYmqJhGIqaMEIWNvrTWPAxXJnFkFrun2Sgv16vULvHpDAiBSamHHFNQNwsiBfFlSOfx489+Azwrt/S6gfzG19kYPv96K5N/ZhgwmSCu66DQsO96Hif5RQTE0ROKz7eaYq42QQLwiHn7aufnF/KwL7vHrA1CcSQJDKm0GTEM1vk3IkNHndykqc4ru6/fKHtI7e4gt5w/2RHRU+JIWKu896jWIIDI8DfXBbF50PIHCQHYm6yD6ykAXyAnFATg4OPjKtOQk2zVpXZCMVgsJbmSLN1HXFSgClIrLcw0J+vj5G6w+L8J4jE+NJDWicgddZyMjWngfhGyJF8Xt3SpIYa+Et79PgbldvPX/0+gCseFckilRCE57snmgiKaI3vI/JC0osMFa/24uT+2f3swLi5NxpQPsghHDHSKKmewhUHR/NGnVCShMeGUyqNwAqQedcHyKZtsr6Nu2eHWiHMt5ms0sjPJyFjhcu6pOpEUnch35q/q07t5hV7if85b5q33ZgWaP4kqY5Rd49rKFMx5UJZhvkIQ4ULPpP2G/+MNn766n0Z+AOzOsYPIHdRtQAAAABJRU5ErkJggg==");
          }
        }
      }
    }
  }


  .active {
    color: #006eff;
  }
}

.white {
  background-color: white;
  color: black;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, .12), 0 0 6rem rgba(0, 0, 0, .04)
}

input[type="password"]::-ms-reveal {
  display: none
}


</style>
