<template>
    <div class="flexs">

        <div class="title">
            <div>郵箱登錄</div>
        </div>
        <div class="login-container">
            <div class="phone">
                <input class="username" v-model="ruleForms.email" placeholder="請輸入郵箱" />
            </div>
            <div class="verifyRes">
                <input type="text" class="passworld" v-model="ruleForms.code" placeholder="請輸入短信驗證碼"
                    style="position: relative;" />
                <div class=" btn">
                    <div v-if="!countDown" class="child" @click="getVerifyMsg">发送验证码</div>
                    <div v-else class="child">{{ secondes + `s` }}</div>
                </div>
            </div>
            <div class="totas">
                <div @click="back" class="forgot">
                    {{ fast_rej }}
                </div>
            </div>
            <div class="submits">
                <div class="submit" @click="login">
                    <div class="text">
                        立即登錄
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import { ElMessage } from 'element-plus'
import JSEncrypt from 'jsencrypt'
import * as api from "@/api/login/login.js"

let data = ref() // 密文
const countDownSecondes = ref(60); // 倒计时时间
const secondes = countDownSecondes; // 倒计时

const ruleForms = reactive({
    email: "",
    code: ""
})
const countDown = ref(false) // 判断是否倒计时

const emit = defineEmits(['back'])

const back = () => {
    console.log(111);
    ruleForms.email = "";
    ruleForms.code = "";
    emit("back")
}

const props = defineProps({
    title: {
        type: String,
    },
    texts: {
        type: String,
    },
    fast_rej: {
        type: String,
    },
    status: {
        type: Number,
    }
});
// 邮箱校验
// const validatePhoneNumber = (phoneNumber) => {
//     const pattern = /^1[3456789]\d{9}$/;
//     return pattern.test(phoneNumber);
// }
// 获取验证码
const getVerifyMsg = () => {
    if (!ruleForms.email) {
        // prompts.value = "請輸入手機號";
        ElMessage.error('請輸入邮箱')
        return
    }
    const encrypt = new JSEncrypt()
    getPublicKey((publicKey) => {
        countDown.value = true;
        countDownFun();
        encrypt.setPublicKey(publicKey)
        data.value = encrypt.encrypt(JSON.stringify(Number(ruleForms.email))); // 加密的邮箱
        // api.sendPhone({ phone: data.value }).then((res) => { // 发送邮箱的接口
        //     if (res.data.status === 1) {
        //         ElMessage({
        //             message: '獲取成功',
        //             type: 'success',
        //         })
        //         // 获取成功提示逻辑
        //     } else {
        //         // prompts.value = "聯系管理員";
        //         ElMessage.error('聯系管理員')
        //     }
        // }).catch((err) => {
        //     requestFailed(err);
        // });
    });

}
// 倒计时逻辑
const countDownFun = () => {
    let timer = setInterval(() => {
        countDownSecondes.value -= 1;
        if (countDownSecondes.value === -1) {
            clearInterval(timer);
            countDown.value = false;
            countDownSecondes.value = 10
        }
    }, 1000);
}

// 密文加密
const getPublicKey = (callable) => {
    api.getPublicKey().then((res) => {
        callable(res.data)
    });
}
</script>

<style lang="scss" scoped>
.flexs {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.title {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: 109rem;
    display: flex;
    justify-content: space-evenly;
}

.login-container {

    ::placeholder {
        font-size: 16rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #BDBDBD;
    }

    .phone {
        display: flex;
        justify-content: center;
        width: 380rem;
        margin: 55rem auto 20rem;

        .toggle {
            border-radius: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90rem;
            height: 55rem;
            background: #F2F2F2;

            .num {
                width: 90rem;
                height: 18rem;
                font-size: 18rem;
                border-right: 2rem solid #aaaaaa;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 20rem;
                display: flex;
                padding-right: 10rem;
                justify-content: center;
            }
        }

        .username {
            padding-left: 21rem;
            border-radius: 8rem;
            height: 55rem;
            width: 100%;
            border-left: none;
            background: #F2F2F2;
            border: none;
            outline: none;
            font-size: 18rem;
            font-weight: bold;
            color: #333333;
        }
    }

    .verifyRes {
        display: flex;
        justify-content: center;
        align-items: center;

        .passworld {
            width: 226rem;
            height: 54rem;
            border-radius: 8rem;
            border-left: none;
            background: #F2F2F2;
            border: none;
            outline: none;
            font-size: 22rem;
            font-weight: bold;
            padding-left: 21rem;
            color: #333333;
        }

        .btn {
            width: 134rem;
            height: 53rem;
            background: linear-gradient(90deg, #8553F9 0%, #67FBD0 100%);
            border-radius: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20rem;


            .child {
                border: none;
                border-radius: 8rem;
                width: 129rem;
                height: 49rem;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #7B89EC;

            }
        }


    }

    .totas {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-top: 20rem;

        .el-icony {
            width: 16rem;
            height: 15rem;
            position: relative;
            top: 1rem;
            left: 0;
        }

        .forgot {
            cursor: pointer;
            font-size: 14rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #7B89EC;
        }


    }

    .submits {
        display: flex;
        align-items: center;
        justify-content: center;

        .submit {
            width: 380rem;
            height: 62rem;
            background: linear-gradient(90deg, #8552F9, #67FCD0);
            border-radius: 30rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20rem;
            margin-bottom: 20rem;
            cursor: pointer;

            .text {
                width: 81rem;
                font-size: 20rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }

    .totals {
        .span {
            width: 100%;
            text-align: center;
            height: 15rem;
            font-size: 14rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #7B89EC;
            line-height: 24rem;
        }
    }
}
</style>