<template>
  <div class="container">
    <div class="lineOne">
            <div class="lineOneLeft"></div>
            <div class="lineOneRight"></div>
        </div>
        <div class="lineTwoABC">
            <div class="lineTwoLeft"></div>
            <div class="lineTwoRight"></div>
        </div>
    <div class="content">
      <div class="conLeft">
        <img src="../../assets/new/02@2x.png" alt="">
      </div>
      <div class="conRight" v-show="show === 1 ? true : false">
        <!-- <div @click="goMain" class="goBack">
          <el-icon class="el-icons">
            <Close />
          </el-icon>
        </div> -->
        <div style="width: 100%;display: flex;align-items: center;justify-content: center;height: 110rem;">
          <img src="../../assets/new/01@2x.png" style="width: 160rem;" alt="">
        </div>
        <div class="title">
          <div @click="toggles(2)" :class="toggle === 2 ? 'right text active' : 'right text'">用戶登入</div>
          <!-- <div @click="toggles(1)" :class="toggle === 1 ? 'left text active' : 'left text'">郵箱快捷登錄</div> -->
        </div>
        <!-- <div class="prompt" v-if="prompts === '' ? false : true">
          <img src="./image/totas.png" alt="">
          <span class="text">{{ prompts }}</span>
        </div> -->
        <div class="login-container">
          <div class="phone">
            <div class="toggle">
              <div class="num">
                郵箱
              </div>
            </div>
            <input class="username" v-model="ruleForm.username" placeholder="請輸入郵箱" />
          </div>
          <div class="verifyRes">
            <input v-if="toggle === 1 ? true : false" type="text" class="passworld" v-model="ruleForm.verifyRes"
              placeholder="請輸入驗證碼" style="position: relative;" />
            <input v-else :type="inputType" class="passworld" v-model="ruleForm.passworld" placeholder="請輸入密碼"
              style="position: relative;width: 380rem;" />
            <div class="eye" style="position: absolute;right: 70rem;top: 318rem;" @click="togglePasswordVisibility">
              <img class="eyes" v-show="toggle === 2 ? true : false"
                :src="inputType === 'password' ? require('./image/eye.png') : require('./image/biyan.png')" alt="">
            </div>
            <div class=" btn" v-show="toggle === 1 ? true : false">
              <div v-if="!countDown" class="child" @click="getVerifyMsg">獲取驗證碼</div>
              <div v-else class="child">{{ secondes + `s` }}</div>
            </div>
          </div>
          <div class="submits">
            <div class="submit" @click="login">
              <div class="text">
                登入
              </div>
            </div>
            <!-- <el-button class="submit" :plain="true" @click="login">立即登录</el-button> -->
          </div>
          <div class="totals">
            <div  style=" cursor: pointer;margin-bottom: 20rem;" class="span" @click="forgetPass">
              忘記密碼？
            </div>
            <div class="span" v-if="toggle === 1">
              使用郵箱快捷登錄，無賬號將自動註冊
            </div>
            <div v-else style=" cursor: pointer;" class="span" @click="rej()">
              暫無賬號，去註冊
            </div>
          </div>
        </div>
      </div>
      <forget v-show="show === 2 ? true : false" @goBack="goBack" :status="show" :fast_rej="find.submit"
        :texts="find.findpass_text" :title="find.findpass" @back="logins">
      </forget>
      <forget v-show="show === 3 ? true : false" @goBack="goBack" :status="show" :fast_rej="find.fast_rej"
        :texts="find.rej_text" :title="find.rej" @back="logins"></forget>
      <email v-show="show === 4 ? true : false" :fast_rej="find.theAccout" :status="show" @back="accountLogin">
      </email>
    </div>

    <!-- 底部栏 -->
    <div class="footer">
      <div class="footerLeft">
        <div>
          電郵:services@alo-global.com
        </div>
        <div>
          電話:+852 6908 9802
        </div>
        <div>
          地址:香港中環雲咸街73號雲山大廈21樓
        </div>
        <div>
          備案號: <a style="text-decoration: none;" target="_blank"  href="https://beian.miit.gov.cn">粤ICP備2024266427號</a>
        </div>
      </div>
      <div class="footerRight">
        ©2024 父母心有限公司 版權保留條款及細則隱私政策
      </div>
    </div>
  </div>
</template>

<script setup>
import forget from "./components/forget.vue"
import email from "./components/eMailLogin.vue"
import JSEncrypt from 'jsencrypt'
import * as api from "@/api/login/login.js"
import { ref, reactive, nextTick } from "vue"
import { ElMessage } from 'element-plus'
import { useRouter } from "vue-router"

const countDown = ref(false) // 判断是否倒计时
const countDownSecondes = ref(60); // 倒计时时间
const secondes = countDownSecondes; // 倒计时
const toggle = ref(2); // 1是验证码登陆 2是密码登陆
const timeStamp = Date.now() // 时间戳
let data = ref() // 密文
const inputType = ref('password'); // 文本密码切换
const secret = ref("d2e96a24a7ba09469b6d6e3a20e0afa7_hk") // 企业标识符
const secretAuth = ref("d2e96a24a7ba09469b6d6e3a20e0afa7") // 企业标识符
const find = reactive({
  findpass: "找回密碼",
  findpass_text: "返回登錄",
  rej: "郵箱快速註冊",
  rej_text: "已有賬號，去登錄",
  fast_rej: "立即註冊",
  submit: "提交",
  theAccout: "賬號密碼登錄"
})
const show = ref(1) // 1 是正常登陆 2 是找回密码 3 是快速注册
const ruleForm = reactive({
  username: "",
  verifyRes: "",
  passworld: ""
})
const prompts = ref("") // 提示信息

const Router = useRouter()

const PasswordVerification = (passNumber) => {
  const pattern = /^[a-zA-Z0-9]{6,20}$/;
  return pattern.test(passNumber);
}

const validatePhoneNumber = (phoneNumber) => {
  var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(phoneNumber);
}

const togglePasswordVisibility = () => {
  inputType.value = (inputType.value === 'password') ? 'text' : 'password';
}

const toggles = (val) => {
  if (val === 1) {
    toggle.value = 1
    ruleForm.verifyRes = ""
  } else {
    toggle.value = 2
    ruleForm.passworld = ""
  }
}

const forgetPass = () => {
  show.value = 2
}

const eMailLogin = () => {
  show.value = 4
}

const logins = () => {

  show.value = 1
  ruleForm.verifyRes = ""
  ruleForm.passworld = ""
}

const accountLogin = () => {
  console.log(1231212);
  show.value = 1
  toggle.value = 2
}

const rej = () => {
  show.value = 3
}

const goBack = () => { // 放回上一页
  Router.go(-1)
}

const goMain = () => {
  console.log(123)
  Router.push({
    path: '/'
  })
}

// 手机号快捷登陆、获取验证码
const getVerifyMsg = () => {
  if (!ruleForm.username) {
    // prompts.value = "請輸入手機號";
    ElMessage.error('請輸入郵箱')
    return
  }
  if (validatePhoneNumber(ruleForm.username)) {

    const encrypt = new JSEncrypt()
    getPublicKey((publicKey) => {
      countDown.value = true;
      countDownFun();
      encrypt.setPublicKey(publicKey)
      data.value = encrypt.encrypt(ruleForm.username);

      api.sendPhone({ email: data.value }).then((res) => {
        if (res.data.status === 1) {
          ElMessage({
            message: '獲取成功',
            type: 'success',
          })
          // 获取成功提示逻辑
        } else {
          // prompts.value = "聯系管理員";
          ElMessage.error(res.data.msg)

        }
      }).catch((err) => {
        requestFailed(err);
      });
    });
    return
  } else {
    // prompts.value = "手機號碼格式錯誤";
    ElMessage.error('郵箱錯誤')
  }
}

// 验证码登陆
const login = () => {
  const encrypt = new JSEncrypt()
  if (!ruleForm.username && !ruleForm.passworld && !ruleForm.verifyRes) {
    // prompts.value = "請填寫相關信息";
    ElMessage({
      message: '請填寫相關信息',
      type: 'warning',
      center: true,
    })
    // ElMessage.error('請填寫相關信息')
    return
  }
  getPublicKey((publicKey) => {
    encrypt.setPublicKey(publicKey)
    let dataVify = `${secret.value}_${ruleForm.username}_${ruleForm.verifyRes}_${timeStamp}`
    if (!PasswordVerification(ruleForm.passworld) && toggle.value === 2) {
      // prompts.value = "密碼由數字、字母組成的6-20位數";
      ElMessage.error('密碼由數字、字母組成的6-20位數')
      return
    }
    let dataPass = `${secret.value}_${ruleForm.username}_${ruleForm.passworld}_${timeStamp}`
    let newCode = encrypt.encrypt(toggle.value === 1 ? dataVify : dataPass);
    if (!validatePhoneNumber(ruleForm.username)) {
      // prompts.value = "手機號碼格式錯誤";
      ElMessage.error('郵箱錯誤')
      return
    }
    api.login({
      encryption: newCode,
      timeStamp: timeStamp,
      type: toggle.value // 1是验证码登陆 ，2是密码登陆
    }).then(res => {
      if (res.data.status === 1) {
        ElMessage({
          message: '登陸成功',
          type: 'success',
        })


        prompts.value = "";
        localStorage.setItem('token', res.data.token);
        localStorage.setItem("phone", ruleForm.username)
        getAuth()
        localStorage.setItem("phone", ruleForm.username)
        localStorage.setItem("randomStr", res.data.randomStr)
        Router.push({
          path: "/createVideo"
        })
      } else if (toggle.value === 1) {
        // prompts.value = "驗證碼錯誤";
        ElMessage.error('驗證碼錯誤')

      } else {
        // prompts.value = "賬號密碼錯誤";
        ElMessage.error('賬號密碼錯誤')
      }
    })
  });
}

// 密文加密
const getPublicKey = (callable) => {
  api.getPublicKey().then((res) => {
    callable(res.data)
  });
}
// getAuth
function getAuth() {
  getPublicKey((publicKey) => {
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(publicKey)
    console.log(`${secret.value}_${ruleForm.username}_${ruleForm.passworld}_${timeStamp}`);
    let dataVify = `${secretAuth.value}_hk_${ruleForm.username}_${timeStamp}`
    let dataPass = `${secretAuth.value}_hk_${ruleForm.username}_${timeStamp}`
    let newCode = encrypt.encrypt(toggle.value === 1 ? dataVify : dataPass);
    //获取是否有用户权限
    api.isAuth({
      encryption: newCode,
      timeStamp: timeStamp,
      // type:2
    }).then(result => {
      if (result.data.status == 1) {
        localStorage.setItem("bool", result.data.bool)
      }
    })
  })
}
// 倒计时逻辑
const countDownFun = () => {
  let timer = setInterval(() => {
    countDownSecondes.value -= 1;
    if (countDownSecondes.value === -1) {
      clearInterval(timer);
      countDown.value = false;
      countDownSecondes.value = 60
    }
  }, 1000);
}
</script>

<style scoped lang="scss">
.prompt {
  position: absolute;
  left: 55rem;
  top: 325rem;

  img {
    width: 18rem;
    height: 18rem;
  }

  .text {
    font-size: 14rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #F4584D;
    vertical-align: top;
    margin-left: 6rem;
  }
}

.goBack {
  cursor: pointer;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: text-focus-in .8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  background-color: #dce8f6;
}

.bgp {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
}

.content {
  // width: 960rem;
  height: 566rem;
  background-color: white;
  border-radius: 20rem;
  overflow: hidden;
  display: flex;
}

.conLeft {
  width: 400rem;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.conRight {
  width: 480rem;
  height: 100%;
  background-color: #FFFFFF;
  position: relative;
  padding-top: 20rem;
  .el-icons {
    position: absolute;
    right: 15rem;
    top: 15rem;
  }

  .title {
    margin-top: 20rem;
    display: flex;
    justify-content: space-evenly;

    .text {
      height: 20rem;
      font-size: 20rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 24rem;
      text-align: center;
    }

    .left {
      width: 50%;
      cursor: pointer;

    }

    .right {
      width: 50%;
      cursor: pointer;

    }

    .active {
      color: #333333;
      position: relative;
      height: 20rem;
      font-size: 20rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24rem;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        background: url("./image/xiahuaxian@2x.png") no-repeat center;
        bottom: -20rem;
        left: 90rem;
        width: 60rem;
        height: 4rem;
      }
    }
  }

  .title {
    text-align: center;
    font-size: 20rem;
    margin-bottom: 20rem;
  }

  .login-container {

    ::placeholder {
      font-size: 16rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #BDBDBD;
    }

    .phone {
      display: flex;
      justify-content: center;
      width: 380rem;
      margin: 55rem auto 20rem;
      border: 1px solid #b4d1d2;
      border-radius: 10rem;
      overflow: hidden;
      .toggle {
        border-top-left-radius: 8rem;
        border-bottom-left-radius: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90rem;
        height: 55rem;

        .num {
          width: 90rem;
          height: 18rem;
          font-size: 18rem;
          border-right: 2rem solid #aaaaaa;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 20rem;
          display: flex;
          justify-content: center;
          margin-right: 10rem;
        }
      }

      .username {
        border-top-right-radius: 8rem;
        border-bottom-right-radius: 8rem;
        width: 320rem;
        height: 55rem;
        border-left: none;
        border: none;
        outline: none;
        font-size: 18rem;
        font-weight: bold;
        padding-left: 4rem;
        color: #333333;
      }
    }

    .verifyRes {
      display: flex;
      justify-content: center;
      align-items: center;

      .passworld {
        width: 226rem;
        height: 54rem;
        border-radius: 8rem;
        border-left: none;
        border: none;
        outline: none;
        font-size: 22rem;
        font-weight: bold;
        padding-left: 21rem;
        border: 1px solid #b4d1d2;
        color: #333333;
      }

      .btn {
        width: 134rem;
        height: 53rem;
        background: linear-gradient(90deg, #8553F9 0%, #67FBD0 100%);
        border-radius: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20rem;


        .child {
          border: none;
          border-radius: 8rem;
          width: 129rem;
          height: 49rem;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #7B89EC;

        }
      }


    }

    .totas {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding: 0 55rem;
      margin-top: 20rem;

      .el-icony {
        width: 16rem;
        height: 15rem;
        position: relative;
        top: 1rem;
        left: 0;
      }

      .forgot {
        cursor: pointer;
        font-size: 14rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7B89EC;
      }


    }

    .submits {
      display: flex;
      align-items: center;
      justify-content: center;

      .submit {
        width: 380rem;
        height: 50rem;
        border-radius: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20rem;
        margin-bottom: 20rem;
        cursor: pointer;
        color: black;
        border: 1px solid #b4d1d2;
        .text {
          width: 81rem;
          font-size: 20rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          text-align: center;
        }
      }
    }

    .totals {
      .span {
        width: 100%;
        text-align: center;
        height: 15rem;
        font-size: 14rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #6c8e93;
        line-height: 24rem;
      }
    }
  }
}

.footer {
  position: fixed;
  bottom: 0;
  height: 50rem;
  display: flex;
  padding: 0 20rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16rem;
  background-color: #f9fafe;
}

.footerLeft {
  display: flex;
}

.footerLeft>div {
  margin-right: 30rem
}

.lineOne {
    height: 16rem;
    width: 100%;
    position: fixed;
    top: 0rem;
    display: flex;
}

.lineOneLeft {
    width: 39%;
    height: 100%;
    background-color: #8dc63f;
}

.lineOneRight {
    width: 61%;
    height: 100%;
    background-color: #fbb040;
}

.lineTwoABC {
    height: 16rem;
    width: 100%;
    position: fixed;
    top: 16rem;
    left: 0;
    display: flex;
}

.lineTwoLeft {
    width: 61%;
    height: 100%;
    background-color: #f15a29;
}

.lineTwoRight {
    width: 39%;
    height: 100%;
    background-color: #00aeef;
}
</style>