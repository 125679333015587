<template>
  <div class="container">
    <el-carousel :height="props.bannerHeight + 'rem'">
      <!-- 一张轮播图包含的内容 -->
      <el-carousel-item v-for="item in imgData" :key="item.url">
        <div class="bannerContent">
          <div class="left_img">
            <!-- 标题 -->
            <h1>{{ item.title }}</h1>
            <p>{{ item.content }}</p>
            <button @click="createVideo">立即製作</button>
          </div>
          <img :src="item.url" class="img" alt="" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
const props = defineProps({
  bannerHeight: {
    type: Number, // 参数类型
  },
  imgData: {
    type: Array,
  },
});
const createVideo = () => {
  if (!localStorage.getItem("token")) {
    router.push({
      path: '/login',
      name: '登陆',
    })
  } else {
    if(props.imgData[0].title == '24小時直播'){
      router.push({
      path: '/onlineVideo',
    })
    }else{
      router.push({
      path: '/createVideo',
    })
    }
    
  }

}
</script>

<style scoped lang="scss">
.bannerContent {
  width: 100%;
  display: flex;
  padding: 0 40rem;
  background-color: #040d1e;
  height: 100%;
  justify-content: center;

  .left_img {
    color: white;
    width: 420rem;
    padding-top: 150rem;
    z-index: 10;
    position: absolute;
    left: 400rem;

    h1 {
      font-size: 60rem;
    }

    h1,
    p {
      width: 100%;
      margin-top: 40rem;
    }

    button {
      width: 200rem;
      height: 50rem;
      background-color: #009aff;
      border: none;
      border-radius: 50rem;
      font-size: 18rem;
      margin-top: 40rem;
      cursor: pointer;
      color: white;
    }
  }

  img {
    position: absolute;
    left: 600rem;
    top: 0;
    height: 100%;
  }
}
</style>