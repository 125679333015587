<template>
    <div class="conRight">
        <!-- 手机号快捷登录 -->
        <!-- <div>
            <el-icon @click="goBack" class="goBack">
                <Close />
            </el-icon>
        </div> -->
        <div class="title">
            <div :class="toggle === 1 ? 'left text active' : 'left text'">{{ title }}</div>
        </div>
        <div class="login-container">
            <div class="phone">
                <div class="toggle">
                    <div class="num">
                        郵箱
                    </div>
                </div>
                <input class="username" v-model="ruleForm.username" placeholder="請輸入郵箱" />
            </div>
            <div class="verifyRes">
                <input v-if="toggle === 1 ? true : false" type="text" class="passworld" v-model="ruleForm.verifyRes"
                    placeholder="請輸入驗證碼" style="position: relative;" />

                <div class=" btn" v-show="toggle === 1 ? true : false">
                    <div v-if="!countDown" class="child" @click="getVerifyMsg">獲取驗證碼</div>
                    <div v-else class="child">{{ secondes + `s` }}</div>
                </div>
            </div>
            <div class="pass">
                <input :type="inputType" class="passworld" v-model="ruleForm.passworld"
                    placeholder="設置密碼（至少由數字、字母組成的6-20位數）" style="position: relative;width: 380rem;" />

                <input :type="inputType" class="passworld" v-model="ruleForm.agPass" placeholder="再次輸入密碼"
                    style="position: relative;width: 380rem;" />

                <div class="eye" style="position: absolute;right: 70rem;top: 85rem;" @click="togglePasswordVisibility">
                    <img class="eyes"
                        :src="inputType === 'password' ? require('./image/eye.png') : require('./image/biyan.png')" alt="">
                </div>
            </div>

            <div class="submits">
                <div class="totas">
                    <div class="prompt" v-if="prompts === '' ? false : true">
                        <img src="./image/totas.png" alt="">
                        <span class="text">{{ prompts }}</span>
                    </div>
                </div>
                <div class="submit" @click="findpass">
                    <div class="text">
                        {{ fast_rej }}
                    </div>
                </div>
            </div>
            <div class="totals">
                <div style=" cursor: pointer;" class="span" @click="back">
                    {{ texts }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
const countDown = ref(false) // 判断是否倒计时
const countDownSecondes = ref(60); // 倒计时时间
const secondes = countDownSecondes; // 倒计时
const toggle = ref(1); // 1是验证码登陆 2是密码登陆
const timeStamp = Date.now() // 时间戳
import JSEncrypt from 'jsencrypt'
import * as api from "@/api/login/login.js"
import { ElMessage } from 'element-plus'

const prompts = ref("") // 提示信息
let data = ref() // 密文
const inputType = ref('password');
const secret = ref("d2e96a24a7ba09469b6d6e3a20e0afa7_hk") // 企业标识符
const ruleForm = reactive({
    username: "",
    verifyRes: "",
    passworld: "",
    agPass: ""
})

const props = defineProps({
    title: {
        type: String,
    },
    texts: {
        type: String,
    },
    fast_rej: {
        type: String,
    },
    status: {
        type: Number,
    }
});

const validatePhoneNumber = (email) => { // 手机号校验
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

const PasswordVerification = (passNumber) => { //  密码校验
    const pattern = /^[a-zA-Z0-9]{6,20}$/;
    return pattern.test(passNumber);
}

const togglePasswordVisibility = () => {
    inputType.value = (inputType.value === 'password') ? 'text' : 'password';
}

const emit = defineEmits(['back', 'goBack'])
const back = () => {
    ruleForm.username = "";
    ruleForm.verifyRes = "";
    ruleForm.passworld = "";
    ruleForm.agPass = "";
    emit("back")
}

const goBack = () => {
    emit("goBack")
}

// 获取验证码
const getVerifyMsg = () => {
    console.log(props.title)
    if (!ruleForm.username) {
        console.log("請輸入郵箱");
        return
    }
    if (validatePhoneNumber(ruleForm.username)) {
        const encrypt = new JSEncrypt()
        getPublicKey((publicKey) => {

            encrypt.setPublicKey(publicKey)
            data.value = encrypt.encrypt(ruleForm.username);
            // 校验是否注册
            let dataCode = `${secret.value}_${ruleForm.username}_${timeStamp}`
            let newCode = encrypt.encrypt(dataCode);
            if (props.title == '找回密碼') {
                countDown.value = true;
                countDownFun();
                api.sendPhone({ email: data.value }).then((res) => {
                    if (res.data.status === 1) {
                        ElMessage({
                            message: '獲取成功',
                            type: 'success',
                        })
                    } else {
                        ElMessage({
                            message: res.data.msg,
                            type: 'error',
                        })
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                api.check({
                    encryption: newCode,
                    timeStamp: timeStamp,
                }).then(e => {
                    console.log(e)
                    if (e.data.isHas == 1) {
                        ElMessage({
                            message: '该账号已注册',
                            type: 'error',
                        })
                    } else {
                        countDown.value = true;


                        countDownFun();
                        api.sendPhone({ email: data.value }).then((res) => {
                            if (res.data.status === 1) {
                                ElMessage({
                                    message: '獲取成功',
                                    type: 'success',
                                })
                            } else {
                                ElMessage({
                                    message: res.data.msg,
                                    type: 'error',
                                })
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                })
            }


        });
        return
    } else {
        prompts.value = "邮箱格式錯誤"
    }
}

// 找回密码
const findpass = () => {
    if (!validatePhoneNumber(ruleForm.username)) {
        prompts.value = "邮箱格式錯誤"
        return
    }
    if (ruleForm.passworld !== ruleForm.agPass) {
        prompts.value = "兩次密碼不壹致"
        return
    }
    const encrypt = new JSEncrypt()
    getPublicKey((publicKey) => {
        encrypt.setPublicKey(publicKey)
        if (!PasswordVerification(ruleForm.passworld)) {
            prompts.value = "密碼由數字、字母組成的6-20位數";
            return
        }
        let dataCode = `${secret.value}_${ruleForm.username}_${ruleForm.verifyRes}_${ruleForm.passworld}_${timeStamp}`
        let newCode = encrypt.encrypt(dataCode);
        if (props.status === 2) {
            console.log(props.status);
            api.updatepassword({
                encryption: newCode,
                timeStamp: timeStamp,
            }).then((res) => {
                if (res.data.status === 1) {
                    ElMessage({
                        message: '修改成功',
                        type: 'success',
                    })
                    emit("back")
                }
            })
        } else {
            console.log(props.status);
            api.register({
                encryption: newCode,
                timeStamp: timeStamp,
            }).then((res) => {
                if (res.data.status == 1) {
                    ruleForm.username = "";
                    ruleForm.verifyRes = "";
                    ruleForm.passworld = "";
                    ruleForm.agPass = "";
                    ElMessage({
                        message: '註冊成功',
                        type: 'success',
                    })
                    emit("back")
                } else {
                    prompts.value = res.data.msg
                    return
                }
            })
        }

    });
}

// 倒计时逻辑
const countDownFun = () => {
    let timer = setInterval(() => {
        countDownSecondes.value -= 1;
        if (countDownSecondes.value === -1) {
            clearInterval(timer);
            countDown.value = false;
            countDownSecondes.value = 60
        }
    }, 1000);
}

// 密文加密
const getPublicKey = (callable) => {
    api.getPublicKey().then((res) => {
        callable(res.data)
    });
}

</script>

<style lang="scss" scoped>
.goBack {
    cursor: pointer;
}

.conRight {
    width: 480rem;
    height: 100%;
    background-color: #FFFFFF;
    position: relative;

    .el-icon {
        position: absolute;
        right: 15rem;
        top: 15rem;
    }

    .title {
        margin-top: 50rem;
        display: flex;
        justify-content: space-evenly;

        .text {
            height: 20rem;
            font-size: 20rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            line-height: 24rem;
            text-align: center;
        }

        .left {
            width: 50%;
            cursor: pointer;

        }

        .right {
            width: 50%;
            cursor: pointer;

        }

        .active {
            color: #333333;
            position: relative;
            height: 20rem;
            font-size: 20rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 24rem;
            text-align: center;
        }
    }

    .title {
        text-align: center;
        font-size: 20rem;
        margin-bottom: 20rem;
    }

    .login-container {

        ::placeholder {
            font-size: 16rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #BDBDBD;
        }


        .phone {
            display: flex;
            justify-content: center;
            width: 380rem;
            margin: 40rem auto 20rem;

            .toggle {
                border-top-left-radius: 8rem;
                border-bottom-left-radius: 8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90rem;
                height: 55rem;
                background: #F2F2F2;

                .num {
                    width: 80rem;
                    height: 18rem;
                    font-size: 18rem;
                    border-right: 2rem solid #aaaaaa;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20rem;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    margin-right: 10rem;
                }
            }

            .username {
                border-top-right-radius: 8rem;
                border-bottom-right-radius: 8rem;
                width: 320rem;
                height: 55rem;
                border-left: none;
                background: #F2F2F2;
                border: none;
                outline: none;
                font-size: 18rem;
                font-weight: bold;
                padding-left: 4rem;
                color: #333333;
            }
        }

        .verifyRes {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20rem;

            .passworld {
                width: 226rem;
                height: 54rem;
                border-radius: 8rem;
                border-left: none;
                background: #F2F2F2;
                border: none;
                outline: none;
                font-size: 22rem;
                font-weight: bold;
                padding-left: 21rem;
                color: #333333;
            }

            .btn {
                width: 134rem;
                height: 53rem;
                background: linear-gradient(90deg, #8553F9 0%, #67FBD0 100%);
                border-radius: 8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20rem;


                .child {
                    border: none;
                    border-radius: 8rem;
                    width: 129rem;
                    height: 49rem;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #7B89EC;

                }
            }

            .forgot {
                cursor: pointer;
                font-size: 14rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #7B89EC;
            }
        }

        .pass {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            .passworld {
                width: 226rem;
                height: 54rem;
                border-radius: 8rem;
                border-left: none;
                background: #F2F2F2;
                border: none;
                outline: none;
                font-size: 22rem;
                font-weight: bold;
                padding-left: 21rem;
                color: #333333;
                margin-bottom: 20rem;
            }
        }

        .totas {
            .forgot {
                cursor: pointer;
                font-size: 14rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #7B89EC;
            }

            .prompt {
                position: absolute;
                left: 55rem;
                top: 395rem;

                img {
                    width: 18rem;
                    height: 18rem;
                }

                .text {
                    font-size: 14rem;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #F4584D;
                    vertical-align: top;
                    margin-left: 6rem;

                }
            }
        }

        .submits {
            display: flex;
            align-items: center;
            justify-content: center;

            .submit {
                cursor: pointer;
                width: 380rem;
                height: 62rem;
                background: linear-gradient(90deg, #8552F9, #67FCD0);
                border-radius: 30rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20rem;
                margin-top: 15rem;

                .text {
                    text-align: center;
                    width: 81rem;
                    font-size: 20rem;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }

        .totals {
            .span {
                width: 100%;
                text-align: center;
                height: 15rem;
                font-size: 14rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #7B89EC;
            }
        }
    }
}
</style>