import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/login/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/online',
    name: '直播中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/online/index.vue')
  },
  {
    path: '/help',
    name: '帮助中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/help/index.vue')
  },
  {
    path: '/login',
    name: '登陆',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  }, {
    path: '/vipcenter',
    name: '会员中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/vipCenter/index.vue')
  },
  {
    path: '/createVideo',
    name: '立即制作视频',
    component: () => import('../views/createVideo/index.vue')
  },
  {
    path: '/onlineVideo',
    name: '立即制作直播',
    component: () => import('../views/onlineVideo/index.vue')
  },
  {
    path: '/memberCenter',
    name: '个人中心',
    component: () => import('../views/memberCenter/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/createVideo' && !localStorage.getItem("token")) {
    next()
  }
  next()
})
export default router
